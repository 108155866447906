import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  InternalLink,
  OutboundLink,
  Content,
  ImageCollection,
} from "@bluefin/components";
import { Image, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={2} stackable={true} className={"home-hero"}>
              <Background.Color color={"white"} textAlign={"left"}>
                <Image
                  size={"large"}
                  centered={false}
                  src={
                    "https://fisherman.gumlet.io/public/eb332520-85c7-47e6-85fb-93065dcd9954/539_947922428625964_5789820967539815420_n_1.jpeg"
                  }
                />
                <div className={"btn-container"}>
                  <Button
                    primary={true}
                    size={"large"}
                    to={"/menu/"}
                    as={InternalLink}
                  >
                    View Menu
                  </Button>
                  <Button
                    primary={true}
                    size={"large"}
                    to={"https://direct.chownow.com/order/36170/locations"}
                    as={OutboundLink}
                  >
                    Order Now
                  </Button>
                </div>
              </Background.Color>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Content stackable={true}>
            <Content.Image
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "Content.Image",
                componentIdentifier: "about",
                numToSelect: 1,
              })}
            />
            <Content.Markup truncate={true} paragraphs={2}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
              numToSelect: 2,
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={2}
          />
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
